<script lang="ts" setup>
import { DeviceState } from "@/services/BackendService";
import { ref, computed, onMounted, onBeforeUnmount, watch, nextTick } from "vue"
import MagnifyingGlass from "@/common/components/overlays/MagnifyingGlass.vue"
import { getImageCoords, getMatrix, getScreenCoordinates, getTypeOfClick } from "@/services/DomHelperFunctions";
import ArrowHead from "@/common/components/overlays/ArrowHead.vue"
import AnchorAreaRect from "@/common/components/overlays/AnchorAreaRect.vue"
import type { ActionDTO, AnnotationDTO } from "@/types/gen";
import { ActionInput } from "@/types/gen";
import VHelpText from "@/common/components/layout/VHelpText.vue"
import VAnnotation from "@/common/components/layout/VAnnotation.vue"
import OverlayButton from "@/common/components/viewer/OverlayButton.vue";
import { storeToRefs } from "pinia"
import { useStore } from "@/store/useStore"

interface Props {
    device: DeviceState,
    annotations?: AnnotationDTO[],
    magnify?: boolean,
    drawable?: boolean,
    allowEdit?: boolean,
    hideUseButton?: boolean
    currentAction?: ActionDTO
}

const props = defineProps<Props>()

const emit = defineEmits<{
    (event: "pointerstart", data: any, matrix: SVGMatrix): void
    (event: "newRect", rect: DOMRect, callback: Function) : void
    (event: "updateRect", data: DOMRect, callback: Function) : void
    (event: "newSwipe", data: {pos1: DOMPoint, pos2: DOMPoint, type:string}) : void
    (event: "click", data: { type: string, pos: DOMPoint}): void
    (event: "updateClick"): void
    (event: "deleteAnnotation", data: AnnotationDTO): void,
    (event: "updateAnnotation", data: AnnotationDTO): void,
    (event: "retakeAnnotation", data: AnnotationDTO): void
}>()

const { dragged } = storeToRefs(useStore())

const clickableArea = ref<HTMLElement>()
const frameImage = ref<HTMLImageElement>()
const startPos = ref(new DOMPoint())
const endPos = ref(new DOMPoint())
const rect = ref<DOMRect | undefined>()
const editFrame = ref<any>()
const hoveredKey = ref(-1)

const imageUrl= computed(() => props.device.imageUrl)
const hasImageUrl= computed(() => imageUrl.value != null)
const containerHeight = ref(0)
const containerWidth = ref(0)
const imageHeight = ref(0)
const imageWidth = ref(0)
const overlayHeight = ref(0)
const overlayWidth = ref(0)
const scale = ref(1.0)
let matrix: SVGMatrix

const isClick= computed(() => (rect.value?.width || 0) < 10 && (rect.value?.height || 0) < 10)
const isSwipe= computed(() => ((rect.value?.width || 0) < 10 || (rect.value?.height || 0) < 10) && !isClick.value)

watch(frameImage, async() => {
    await nextTick();
    if (frameImage.value)
       frameImage.value!.onload = refreshLayout
})

onMounted(async () => {
    clickableArea.value?.addEventListener("contextmenu", (event) => event.preventDefault())
    window.addEventListener('resize', refreshLayout)
    refreshLayout()
})

onBeforeUnmount(() =>
    window.removeEventListener('resize', refreshLayout)
)

function pointerStart(event: UIEvent) {
    event.preventDefault()
    if ((event as any)['noClick']) return
    refreshLayout()
    if (props.drawable) {
        startRect(event)
    } else {
        emit('pointerstart', event, getMatrix(frameImage.value!))
    }
}

function getCoords(event:UIEvent) {
    if (!matrix) {
        refreshLayout()
    }
    return matrix.inverse().transformPoint(getScreenCoordinates(event))
}

function startRect(event: UIEvent) {
    startPos.value = getCoords(event)
    endPos.value = startPos.value
    rect.value= new DOMRect(startPos.value.x, startPos.value.y, 0, 0)

    function moveRect(event: UIEvent) {
        event.preventDefault()
        endPos.value = getCoords(event)
        const x1 = Math.max(0, Math.min(startPos.value.x, endPos.value.x))
        const y1 = Math.max(0, Math.min(startPos.value.y, endPos.value.y))
        const x2 = Math.min(Math.max(startPos.value.x, endPos.value.x), imageWidth.value)
        const y2 = Math.min(Math.max(startPos.value.y, endPos.value.y), imageHeight.value)
        rect.value = new DOMRect(x1, y1, x2-x1, y2-y1)
    }

    function stopRect(event: UIEvent) {
        moveRect(event)
        window.removeEventListener("mousemove", moveRect)
        window.removeEventListener("mouseup", stopRect)
        window.removeEventListener("touchmove", moveRect)
        window.removeEventListener("touchend", stopRect)
        const type = getTypeOfClick(event as PointerEvent)
        if (isClick.value) {
            emit('click', {type, pos: new DOMPoint(rect.value!.x, rect.value!.y)})
            rect.value = undefined
        } else if (isSwipe.value) {
            emit('newSwipe', {
                pos1: startPos.value,
                pos2: endPos.value,
                type
            })
            rect.value = undefined
        } else {
            const callback = () => { rect.value = undefined }
            emit('newRect', rect.value!, callback)
        }
    }
    window.addEventListener("mousemove", moveRect)
    window.addEventListener("mouseup", stopRect)
    window.addEventListener("touchmove", moveRect)
    window.addEventListener("touchend", stopRect)
}

async function refreshLayout() {
    if (!frameImage.value?.naturalHeight) return
    containerHeight.value= clickableArea.value!.offsetHeight
    containerWidth.value= clickableArea.value!.offsetWidth
    imageHeight.value = frameImage.value?.naturalHeight
    imageWidth.value = frameImage.value?.naturalWidth
    if (frameImage.value) {
        scale.value = Math.min(
            containerHeight.value / imageHeight.value,
            containerWidth.value / imageWidth.value
       ) || 1.0
       overlayHeight.value= imageHeight.value * scale.value
       overlayWidth.value= imageWidth.value * scale.value
    }

    if (!frameImage.value?.clientHeight) return
    matrix = getMatrix(frameImage.value!)
}

function mouseDownHandle(evt: UIEvent, corner: any) {
    const xfield = 'x' + corner.x
    const yfield = 'y' + corner.y
    const startX = props.device.nextFrame[xfield]
    const startY = props.device.nextFrame[yfield]
    editFrame.value = Object.assign({}, props.device.nextFrame)
    const from = getCoords(evt)

    function move(event: UIEvent) {
        const to = getCoords(event)
        editFrame.value[xfield] = startX + to.x - from.x
        editFrame.value[yfield] = startY + to.y - from.y
    }

    function up(event: UIEvent) {
        const callback = () => { editFrame.value = undefined }
        const f = editFrame.value!
        useCandidate(f, callback)

        editFrame.value = undefined
        window.removeEventListener('mousemove', move)
        window.removeEventListener('touchmove', move)
        window.removeEventListener('mouseup', up)
        window.removeEventListener('touchend', up)
    }
    window.addEventListener('mousemove', move)
    window.addEventListener('touchmove', move)
    window.addEventListener('mouseup', up)
    window.addEventListener('touchend', up)
}

function mouseDownClick(evt: UIEvent, click: ActionInput) {
    (evt as any)['noClick']= true
    evt.preventDefault()
    const from = getCoords(evt)
    const startX = click.x
    const startY = click.y
    const frame= props.device.nextFrame
    let moveInputMode= false

    function move(event: UIEvent) {
        moveInputMode = true
        const to = getCoords(event)
        click.x = startX + (to.x - from.x) / (frame.x1 - frame.x0)
        click.y = startY + (to.y - from.y) / (frame.y1 - frame.y0)
    }

    function up(event: UIEvent) {
        if (click.type=="CLICK" && !moveInputMode) click.type = ActionInput.type.DOUBLE_CLICK
        else if (click.type=="DOUBLE_CLICK" && !moveInputMode) click.type = ActionInput.type.CLICK
        emit("updateClick")
        window.removeEventListener('mousemove', move)
        window.removeEventListener('touchmove', move)
        window.removeEventListener('mouseup', up)
        window.removeEventListener('touchend', up)
    }
    window.addEventListener('mousemove', move)
    window.addEventListener('touchmove', move)
    window.addEventListener('mouseup', up)
    window.addEventListener('touchend', up)
}

function useCandidate(f: any, callback?: Function) {
    emit('updateRect', new DOMRect(
            Math.min(f.x0, f.x1),
            Math.min(f.y0, f.y1),
            Math.abs(f.x0 - f.x1),
            Math.abs(f.y0 - f.y1)), callback ?? (()=>0))
    hoveredKey.value = -1
}

const swipe= computed(() => {
    return props.currentAction?.inputs.filter((input: any) =>
                ["CLICK", "RIGHT_CLICK", "DOUBLE_CLICK", "HOVER", "SWIPE"].includes(input.type)
            )
})

const ocr= computed(() => {
    return props.currentAction?.inputs.filter((input: any) =>
            ["OCR"].includes(input.type)
        )
})

function get_x_coOrd(frame: any) {
    if (!frame) return 0
    let x_playIt = frame.x1 * scale.value + 10
    if (x_playIt + 140 > containerWidth.value) x_playIt = frame.x1 * scale.value - 140
    return x_playIt + "px"
}

function get_y_coOrd(frame: any) {
    if (!frame) return 0
    let y_playIt = frame.y1 * scale.value + 10
    if (y_playIt + 20 >= containerHeight.value) y_playIt = frame.y1 * scale.value - 40
    return y_playIt + "px"
}

</script>

<template lang="pug">
.screen-area(ref="clickableArea"
    @mousedown="pointerStart"
    @touchstart="pointerStart"
    :class="{drawable:drawable}"
)
    magnifying-glass(v-if="hasImageUrl " :glassHidden="magnify" :img="frameImage")
    img(v-if="hasImageUrl" ref="frameImage",
        :src="imageUrl",
        :style="{'z-index': -20, 'height': overlayHeight + 'px', width:overlayWidth + 'px'}"
    )
    div.overlay(:style="{width:overlayWidth+'px', height:overlayHeight+'px'}" v-if="currentAction")
        overlay-button.candidateBtn.visible(
            v-if="!hideUseButton"
            v-for="(candidateFrame, index) in (device.candidates ?? [])",
            :x="get_x_coOrd(candidateFrame)" :y="get_y_coOrd(candidateFrame)"
            :key="candidateFrame.x0"
            @mousedown.stop.prevent=""
            @mouseover="hoveredKey = index"
            @mouseout="hoveredKey = -1"
        )
            v-help-text
                .link(@click="$event.isTrusted && !dragged && useCandidate(candidateFrame)") [use]
        overlay-button(
            v-for="annotation in annotations"
            :key="annotation.id"
            :x="get_x_coOrd(annotation.content)" :y="get_y_coOrd(annotation.content)"
        )
            v-annotation(
                :annotation="annotation"
                @deleteAnnotation="emit('deleteAnnotation', $event)"
                @updateAnnotation="emit('updateAnnotation', $event)"
                @retakeAnnotation="emit('retakeAnnotation', $event)"
            )
        overlay-button(:x="get_x_coOrd(device.nextFrame)" :y="get_y_coOrd(device.nextFrame)"
            :follow="true")
            slot(name="play-it")

    svg.overlay(:style="{width:overlayWidth+'px', height:overlayHeight+'px'}")
        defs
            arrow-head#arrowhead
        g(v-if="rect")
            line.swipe(v-if="isSwipe"
                :x1="startPos.x * scale", :y1="startPos.y * scale",
                :x2="endPos.x * scale", :y2="endPos.y * scale"
                marker-end="url(#arrowhead)")
            anchor-area-rect(v-else
                :frame="{x0:rect.x, x1:rect.right, y0:rect.y, y1:rect.bottom}"
                :scaleCoeff="scale"
            )
        g(v-if="currentAction")
            g(v-if="device.candidates")
                anchor-area-rect(
                    v-for="(candidateFrame, index) in device.candidates",
                    :frame="candidateFrame"
                    :scaleCoeff="scale"
                    :strokeGray="true"
                    :isHighlighted="hoveredKey == index"
                )
            g(v-if="currentAction.matcherId && device.nextFrame && device.nextFrame.matched!==false")
                anchor-area-rect(
                    :frame="editFrame || device.nextFrame"
                    :scaleCoeff="scale"
                    :swipe="swipe"
                    :ocr="ocr"
                    :isEditor="true"
                    :showHandles="allowEdit"
                    @mousedownHandle="mouseDownHandle"
                    @mousedownCursorPointer="mouseDownClick"
                )
        g(v-if="annotations")
            anchor-area-rect(v-for="annotation in annotations"
                :frame="annotation.content"
                :scaleCoeff="scale")
</template>

<style scoped>
.screen-area {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: grid;
    place-items: center;
    position: relative
}
.drawable {
    cursor: crosshair;
}
img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    user-select: none;
}

.overlay {
    position: absolute;
    vertical-align: middle;
    align-self: center;
}
.swipe {
    stroke-width: 5px;
    stroke: #94d907;
    fill: none;
}

.candidateBtn {
    cursor: pointer;
    animation-name: cssAnimation;
    animation-duration: 1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes cssAnimation {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
</style>
