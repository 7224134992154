<script lang="ts" setup>
import { computed, onMounted, ref, watch } from "vue"
import { useRoute, useRouter } from "vue-router"
import {
    getTestCase,
    updateTestCase,
    deleteTestCase,
    getActions,
    moveTestCase,
    runTestcases,
    getTestCaseRecordings,
} from "@/services/ProjectService"
import VEditableText from "@/common/components/form/VEditableText.vue"
import VBreadcrumb from "@/common/components/layout/VBreadcrumb.vue"
import VActionButton from "@/common/components/form/VActionButton.vue"
import DetailedView from "@/common/components/layout/DetailedView.vue"
import VHelpText from "@/common/components/layout/VHelpText.vue"
import VEditorOverlay from "@/common/components/layout/VEditorOverlay.vue"
import pluginInjector from "@/plugins/PluginService"
import VNavigateBack from "@/common/components/layout/VNavigateBack.vue"
import { recordingService } from "@/services/RecordingService"
import { useStore } from "@/store/useStore"
import TestcaseRecordingsList from "@/modules/testcase/components/TestcaseRecordingsList.vue"
import TestcaseConfig from "@/modules/testcase/components/TestcaseConfig.vue"
import ProjectSelection from "@/common/components/ProjectSelection.vue"
import type {ActionDTO, ProjectDTO, TestCaseDTO, UpdateRequestPartString, UpdateTestCaseRequest} from "@/types/gen"

const route = useRoute()
const router = useRouter()

const project = ref<ProjectDTO>()
const testcaseId = ref<number | null>(null)
const testcase = ref<TestCaseDTO>()
const testcaseRunning = ref(false)
const actions = ref<ActionDTO[]>([])
const actionsLoaded = ref(false)
const recordings = ref<any[]>([])
const recordingsLoaded = ref(false)
const recordingsReceivedLength = ref(0)
const frameBorderClass = ref("")
const bgClass = ref("")
const pageNumber = ref(-1)
const pageSize = ref(20)


const detailedView = ref<InstanceType<typeof DetailedView>>()

const store = useStore()
const { incUserLevel, runLocked } = store

onMounted(async () => {
    await reload()
})

const openTab = computed(() => {
    return testcase.value ? ( isBrowser.value && !hasUrl.value ? 1 : 0 ) : -1
})

const hasUrl = computed(() => {
    if (testcase.value!.predecessorId) return true
    const url = testcase.value!.config!.url
    return url !== "http://" && !!url.length
})

const isBrowser = computed(() => {
    return testcase.value!.config!.systemName.startsWith('Website');
})

const dropDownItems = computed(() => {
    return pluginInjector.testCaseContextMenu([
        {
            iconClass: ["fas", "fa-sync-alt"],
            text: "Reload",
            callback: reload,
        },
        {
            iconClass: ["fas", "fa-paste"],
            text: "Move To Project",
            callback: openProjectsList,
        },
        {
            iconClass: ["fas", "fa-trash"],
            text: "Delete Test",
            confirm: true,
            callback: deleteTest,
        },
    ], route, router)
})

const hasActions = computed(() => {
    return actionsLoaded.value && (!!actions.value.length || testcase.value?.predecessorId)
})

const noActions = computed(() => {
    return !hasActions.value
})

const hasRecordings = computed(() => {
    return recordingsLoaded.value && !!recordings.value.length
})

const noRecordings = computed(() => {
    return recordingsLoaded.value && !recordings.value.length
})

const playOrStopDisabled = computed(() => {
    return !testcaseRunning.value && ((!hasActions.value && !testcase.value?.config!.url) || (!hasUrl.value && isBrowser.value))
})

async function reload() {
    actionsLoaded.value = false

    testcaseId.value = parseInt(route.params.testcaseId as string)
    if (testcaseId.value) {
        const promises = [reloadRecordings()]
        const getTestCasePromise = getTestCase(testcaseId.value)
        const getActionsPromise = getActions(testcaseId.value)
        testcase.value = await getTestCasePromise
        if (!testcase.value!.config!.variables) testcase.value!.config!.variables = [] // temporary fix
        project.value = testcase.value!.project
        document.title = testcase.value!.name

        actions.value = await getActionsPromise
        actionsLoaded.value = true
        await promises[0]

        // if no recordings present, switch to the project settings page
        if (detailedView.value != null) {
            const tab = (recordings.value.length == 0 && noActions.value) ? 1 : 0
            switchTab(tab)
        }
    }
}

async function reloadRequired() {
    await router.push(`/testcase/${testcaseId.value}`)
    await reload()
}

async function reloadRecordings() {
    const forceReload = (pageNumber.value < 1)

    pageNumber.value = 0
    
    if (forceReload) {
        await loadRecordings()
    }
}

async function runTest() {
    await store.lock
    incUserLevel(5, route)
    if (!hasUrl.value && isBrowser.value) {
        throw new Error("Please enter a URL to proceed")
    }
    router.push(`/run/${testcaseId.value}`)
}

async function goBack() {
    await store.lock
    router.push(`/project/${project.value!.id}`)
}

async function runTestcase() {
    await store.lock
    incUserLevel(6, route)
    testcaseRunning.value = true
    await runTestcases(project.value!, "", testcase.value!.id)
    await reload()

    // switch tab to recordings view, placing it after restart prevent visual problems
    if (detailedView.value != null) switchTab(0)
}

async function saveTest() {
    if (testcase.value!.name != "Name of testcase") incUserLevel(4, route)
    document.title = testcase.value!.name

    const request = {
        name: {value: testcase.value!.name} as UpdateRequestPartString
    } as UpdateTestCaseRequest;

    await runLocked(() => updateTestCase(testcase.value!.id, request))
}

async function deleteTest() {
    await deleteTestCase(testcase.value!)
    router.push(`/project/${project.value!.id}`)
}

function openProjectsList() {
    testcase.value!.move = true
}

function moveToProjectMenuChanged(testCase: TestCaseDTO, showMenu: boolean) {
    testCase.move = showMenu
}

async function moveToProject(testcase: TestCaseDTO, project: ProjectDTO) {
    testcase.move = false
    await moveTestCase(testcase.id, project.id)
    reload()
}

function switchTab(tab: number) {
    if (!detailedView.value) return
    detailedView.value?.updateTab(tab)
}

function colorClassChanged(data: { frameBorderClass: string; bgClass: string }) {
    frameBorderClass.value = data.frameBorderClass
    bgClass.value = data.bgClass
}

async function stopRecordings() {
    await recordingService.stopRecordings(recordings.value)
    updateRecordingsStatus()
    await reload()
}

async function deleteRecording({ recording }: any) {
    recordingsLoaded.value = false
    await recordingService.deleteRecording(recordings.value, recording)
    await reloadRecordings()
}

async function recordingUpdated({ recording }: any) {
    recording ? recordingService.updateRecording(recordings.value, recording) : await reload()
    updateRecordingsStatus()
}

function updateRecordingsStatus() {
    testcaseRunning.value = recordingService.hasRunningRecordings(recordings.value)
}

async function loadRecordings() {
    recordingsLoaded.value = false
    const recordingsReceived = (await getTestCaseRecordings(testcaseId.value!, pageNumber.value, pageSize.value)).recordings
    recordingsReceivedLength.value = recordingsReceived.length

    if (pageNumber.value == 0) {
        recordings.value = recordingsReceived
    } else {
        recordings.value = [...recordings.value, ...recordingsReceived]
    }

    recordingsLoaded.value = true
    updateRecordingsStatus()
}

watch(
    pageNumber,
    () => loadRecordings()
)

async function loadNextPage() {
    if (recordingsLoaded.value && recordingsReceivedLength.value >= pageSize.value) {
        pageNumber.value = pageNumber.value + 1
    }
}

</script>

<template lang="pug">
.test-view.avoid-print-break
    v-editor-overlay(:componentToExecute="'viewer'", @reload="reload")
    detailed-view(
        ref="detailedView",
        :tabNames="['Recordings', 'Test Settings']",
        :openTab="openTab",
        :frameBorderClass="frameBorderClass",
        :bgClass="bgClass"
    )
        template(#navigate-back)
            v-navigate-back(@goBack="goBack")
        template(#title)
            h4
                v-editable-text(v-if="testcase" v-model="testcase.name", @update:modelValue="saveTest")
        template(#breadcrumb)
            v-breadcrumb(
                v-if="project && testcase",
                :projectId="project.id",
                :projectName="project.name",
                :testcaseName="testcase.name",
                :simpleMode="true"
            )
        template(#button-group)
            v-action-button.invisible-in-print(
                :main="!testcaseRunning ? 'Play' : 'Stop'",
                :mainClass="!testcaseRunning ? null : 'btn-danger'",
                :mainIconClass="!testcaseRunning ? ['fas', 'fa-play'] : ['fas', 'fa-stop-circle']",
                :mainDisabled="playOrStopDisabled",
                :secondIconClass="['fas', 'fa-edit']",
                :thirdIconClass="['fas', 'fa-sign-out-alt']",
                :dropDownItems="dropDownItems",
                @main="!testcaseRunning ? runTestcase() : stopRecordings()",
                second="Edit",
                @second="runTest"
            )
        template(#tab0)
            .tu-tab-area.avoid-print-break
                testcase-recordings-list.pt-0.pb-3.avoid-print-break(
                    v-if="!noRecordings",
                    :recordings="recordings",
                    :recordingsLoaded="recordingsLoaded",
                    :enableDownloadMore="recordingsReceivedLength >= pageSize"
                    actionButtonMode="testCaseMode",
                    @recordingUpdated="recordingUpdated",
                    @deleteRecording="deleteRecording",
                    @colorClassChanged="colorClassChanged",
                    @loadNextPage="loadNextPage",
                    @reloadRequired="reloadRequired"
                )
                template(v-else-if="noRecordings")
                    .bg-very-light-grey.p-4
                        v-help-text(v-if="hasActions", @click="runTestcase")
                            span= "You did not run your tests yet. Press "
                            span.link [Play]
                            span= " to start."
                        v-help-text(v-else)
                            span= "You did not record a story board yet. Press "
                            span.link(@click="runTest") [Edit]
                            span= " to start."
        template(#tab1)
            testcase-config.tu-tab-area(
                v-if="testcase",
                v-model="testcase.config",
                :testcase="testcase",
                @runTest="runTest"
            )
                span(v-if="noActions")
                    span= "Press "
                    span.link(@click="runTest") [Edit]
                    span= " to start."

    project-selection(
        v-if="testcase && testcase.move",
        :projectId="project.id",
        :testcase="testcase",
        :expandOnInit="true",
        @moveToProject="moveToProject",
        @showMenuChanged="moveToProjectMenuChanged(testcase, $event)"
    )
</template>

<style lang="css" scoped>
.test-view {
    height: var(--testcase-view-height);
    width: var(--testcase-view-width);
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content 1fr;
    overflow-y: hidden;
    overflow-x: hidden;
}
</style>
